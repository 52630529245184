@import "src/assets/style/variables";
@import "src/assets/style/breakpoint";

.layout {
  height: 100%;
}

.sider {
  padding: $ds-spacing-space_s;
  background: none !important;
}

.siderFooter {
  position: absolute;
  bottom: 24px;
}

.content {
  height: 100%;
  padding : $ds-spacing-space_xs;
  :global(.ant-tabs-content),
  :global(.ant-tabs-tabpane) {
    height: 100%;
  }
}

.logo {
  margin-bottom: $ds-spacing-space_s;
  padding: 28px 0 48px 0;
  text-align: center;
}

@media screen and (max-width: $screen-md) {

  .sider {
    display: none;
  }
  .content {
    padding: 0;
    height: 100%;
  }
}
