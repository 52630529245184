.body1,
.body1variant {
  font-family: "Maax";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #92919E;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.body1Bold,
.body1BoldBis {
  /* Paragraph/P1-Bold */
  font-family: "Maax";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #59566C;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.body1BoldBis {
  font-size: 24px;
  font-weight: 400;
  color: #1f1838;
}
.body1variant {
  color: #59566C;
}
.body2 {
  /* Paragraph/P3-Regular */
  font-family: "Maax";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */

  /* Interface/Content/Subdued */

  color: #92919E;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.body2Bold {
  /* Text-Link/Text-Link-3 */

  font-family: "Maax";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-right: 10px;
  color: #3c2e7f;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
