@import "src/assets/style/breakpoint";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.logo {
  padding : 50px 200px;
  &.hideIfDesktop {
    display: none;
  }
}

.body {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: $screen-sm) {
  .logo {
    display : inherit !important;
  }
}

@media screen and (max-width: $screen-md) {
  .logo {
    align-self: center;
    text-align: center;
    padding : 50px;
  }
}
