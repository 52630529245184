@import "src/assets/style/variables";
@import "src/assets/style/breakpoint";

.loading {
  top: 50%;
  width: 50%;
  transform: translate(50%,-50%);
}

.mainWrapper {
  height: 100%;
  min-width: 400px;
  overflow: auto;

  > :global(.ant-card-body) {
    display: flex;
    gap: $ds-spacing-space_m;
    align-items: baseline;
    flex-wrap: wrap;
  }
}

.bannerWrapper {
  box-shadow: $ds-boxShadow-elevation3;
  &:first-child {
    margin-left: -$ds-spacing-space_m;
  }
}

.bannerMeta {
  margin-top: $ds-spacing-space_xs;
}