@import "../../assets/style/breakpoint";
@import "../../assets/style/variables";

.contentIframe {
  border: none;
  height: 100%;
  width: 100%;
}


@media screen and (max-width: $screen-sm) {
  .scrollDownContainer {
    display: none;
  }
}

:export {
  iphone-inner-height: $iphone-inner-height_raw;
  iphone-inner-width: $iphone-inner-width_raw;
  screen-sm: $screen-sm_raw;
}
