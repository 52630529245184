@import "partials/design-system";

$iphone-inner-width_raw: 324;
$iphone-inner-height_raw: 541;

$iphone-left-offset_raw: 12;
$iphone-top-offset_raw: 42;

$iphone-inner-width: #{$iphone-inner-width_raw}px;
$iphone-inner-height: #{$iphone-inner-height_raw}px;

$iphone-left-offset: #{$iphone-left-offset_raw}px;
$iphone-top-offset: #{$iphone-top-offset_raw}px;

$iphone-left-offset_raw_legacy: 25;
$iphone-top-offset_raw_legacy: 62;

$iphone-left-offset_legacy: #{$iphone-left-offset_raw_legacy}px;
$iphone-top-offset_legacy: #{$iphone-top-offset_raw_legacy}px;

$ipad-inner-width_raw: 482;
$ipad-inner-height_raw: 610;

$ipad-left-offset_raw: 24;
$ipad-top-offset_raw: 24;

$ipad-inner-width: #{$ipad-inner-width_raw}px;
$ipad-inner-height: #{$ipad-inner-height_raw}px;

$ipad-left-offset: #{$ipad-left-offset_raw}px;
$ipad-top-offset: #{$ipad-top-offset_raw}px;
