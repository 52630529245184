@import "src/assets/style/breakpoint";
@import "src/assets/style/variables";


.viewMenuItem {
  display: flex;
  align-items: center;
  
  svg  {
    margin-right: $ds-spacing-space_xs;
  }
}

.breakNewLine {
  overflow-wrap: break-word;
}

.QRcodeBorder {
  border: 1px solid #000000;
  border-radius: 12px;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 160px;
  width: 160px;
}

@media screen and (max-width: $screen-sm) {
  .container {
    display: none;
  }
}

@media screen and (max-width: $screen-md) {
  .container {
    width: 260px;
  }
}
