.container {
  padding : 0 40px;
  text-align: center;
  > img {
    width: 100%;
    max-width: 320px;
    &.resizeImage {
      width: 70px;
    }
  }
}

