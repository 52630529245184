@import "src/assets/style/variables";
@import "src/assets/style/breakpoint";

.header {
  background: none !important;
}

.qrCode {
  border: 1px solid $ds-colors-shape-gray600;
  border-radius: $ds-shapes-rounded_m;
  padding: $ds-spacing-space_xs;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 160px;
  width: 160px;
  background-color: $ds-colors-content-white;
  margin-bottom: $ds-spacing-space_xs;
}

.content {
  height: 100%;
  padding : $ds-spacing-space_xs;
  :global(.ant-tabs-content),
  :global(.ant-tabs-tabpane) {
    height: 100%;
  }
}

.tabSelector,
.templateDisplay {
  margin-bottom: $ds-spacing-space_s;
}

@media screen and (max-width: $screen-md) {
  .sider {
    display: none;
  }

  .content {
    padding: 0;
    height: 100%;
  }
}
