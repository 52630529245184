// integration of font files

$fonts-path: "./fonts/";

@mixin font-face($font-family, $file, $font-weight, $font-style) {
  $file-path: $fonts-path + $file;

  @font-face {
    font-family: $font-family;
    src: local($font-family), url("#{$file-path}.woff2") format("woff2");
    font-weight: $font-weight;
    font-style: $font-style;
  }
}
@include font-face("Maax", "Maax-Light", 300, normal);
@include font-face("Maax", "Maax-Regular", 400, normal);
@include font-face("Maax", "Maax-Medium", 500, normal);

