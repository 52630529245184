.loader {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: flex;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 1;
}

.bar {
  height: 8px;
  width: 90%;
  background: #fff;
  border-radius: 12px;
}

.bar:before {
  border-radius: 12px;
  content: "";
  display: block;
  background-color: #7156f3;
  height: 8px;
  width: 0;
  animation: getWidth 3s ease-in infinite;
}

@keyframes getWidth {
  100% {
    width: 100%;
  }
}
