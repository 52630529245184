@import "src/assets/style/variables";
@import "src/assets/style/breakpoint";

.loading {
  top: 50%;
  width: 50%;
  transform: translate(50%, -50%);
}

@media screen and (min-width: calc($screen-md + 1px)) {
  .phoneContent,
  .tabletContent {
    height: 100%;
    width: 100%;
    background-color: $ds-colors-content-white;
  }

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 400px;
  }

  .phone,
  .tablet {
    display: flex;
    margin: auto;
    box-sizing: content-box;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 6px 8px 0 rgba(191, 202, 217, 0.2);
    border-radius: 24px;
    border: 1px solid #d8dee8;
    background: #f7f7f8;
  }

  .phone {
    width: $iphone-inner-width;
    height: $iphone-inner-height;
    padding: $iphone-top-offset $iphone-left-offset;

    &.landscape {
      width: $iphone-inner-height;
      height: $iphone-inner-width;
      padding: $iphone-left-offset $iphone-top-offset;
    }
  }

  .tablet {
    width: $ipad-inner-width;
    height: $ipad-inner-height;
    padding: $ipad-top-offset $ipad-left-offset;

    &.landscape {
      width: $ipad-inner-height;
      height: $ipad-inner-width;
      padding: $ipad-left-offset $ipad-top-offset;
    }
  }
}

@media screen and (max-width: $screen-md) {
  .card {
    border: none;
    height: 100%;
    :global(.ant-card-body) {
      height: 100%;
      width: 100%;
      padding: 0;
    }
  }

  .tablet,
  .tabletContent,
  .phone,
  .phoneContent {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
