@import "src/assets/style/variables";
@import "src/assets/style/breakpoint";

.mainContainer {
  box-sizing: border-box;
  padding: 0;
  display: flex;
  background: #f9f9fa;
}

.content {
  padding : $ds-spacing-space_xs;
  height: 100%;
}