@import "src/assets/style/variables";
@import "src/assets/style/breakpoint";

.mainWrapper {
  height: 100%;
  min-width: 400px;
  overflow: auto;
  box-shadow: $ds-boxShadow-elevation3;

  > :global(.ant-card-body) {
    display: flex;
    gap: $ds-spacing-space_m;
    flex-direction: column;
    padding: 0 $ds-spacing-space_m;
  }
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: $ds-spacing-space_m;

  :global(.ant-skeleton:first-child) {
    width: 400px;
    height: 86px;
    margin-right: $ds-spacing-space_xl;
  }

  :global(.ant-skeleton) {
    height: 50px;
  }

  :global(.ant-skeleton .ant-skeleton-input),
  :global(.ant-skeleton .ant-skeleton-button) {
    width: 100% !important;
    height: 100% !important;
  }
}

.bannerWrapper {
  :global(.ant-skeleton-button) {
    height: 50px !important;
  }
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-area: content;
  gap: $ds-spacing-space_m;
}

.paragraphPlaceholder {
  :global(.ant-skeleton:first-child) {
    width: 100%;
    margin-bottom: $ds-spacing-space_m;
  }

  :global(.ant-skeleton:first-child .ant-skeleton-image) {
    width: 100%;
  }
}

.creativePlaceholder {
  display: flex;
  align-items: center;
  flex-direction: column;
}
